import closeIcon from '../../assets/close.svg';
import { filterCardProps } from '../../Constants/Types';


export default function FilterCard({ activeFilter, setActiveFilter, filterData }: filterCardProps) {
    return (
        <div className="d-flex wrap">
            {Object.keys(filterData).map((val, index) => (
                <div
                    className={activeFilter === filterData[val].value ? 'filter-card fc-filter-active' : 'filter-card'}
                    key={index}
                    onClick={() => {
                        setActiveFilter(filterData[val].value);
                    }}
                >
                    <div className="fc-icon-wrapper">
                        <div className="fc-img-box">
                            <img src={filterData[val].icon} className="filter-icon" alt={filterData[val].label} />
                        </div>
                        <p className="m-0 pl-1">{filterData[val].label}</p>
                    </div>
                    {activeFilter === filterData[val].value && (
                        <img
                            src={closeIcon}
                            className="close-icon"
                            alt="Close Icon"
                            onClick={(event) => {
                                event.stopPropagation();
                                setActiveFilter('');
                            }}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};