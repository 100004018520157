import Footer from "../Components/UI/Footer";
import ProductCard from "../Components/UI/ProductCard";
// import viewMore from "../assets/viewMore.svg";
import HeroSecondary from "../Components/UI/HeroSecondary";
import HeroHead from "../Components/UI/HeroHead";
import Search from "../Components/UI/search";
import EBike1 from "../assets/E-Bike1.svg";
import ERiksha from "../assets/green 1.svg";
import ELoader from "../assets/e-riksha.svg";
import { Container, Grid, Button,Pagination } from "@mui/material";
import { fetchImage } from "../Api/index";
import { fetchData } from "../Api/index";
import { useState, useEffect } from "react";
import ProductInfoModal from "../Components/UI/ProductInfoModal";
import { Link } from "react-router-dom";
// import { Category } from "../Constants/General";
import BrandList from "../Components/UI/Brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight
 
} from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  const [arr, setArr] = useState([]);
  // const [newArray, setNewArray] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9); // Items per page can be adjusted


  const [childState, setChildState] = useState("");
  const [select, setSelect] = useState("");
  const [cardData, setCardData] = useState({});
  
  const handleChildState = (stateValue) => {
    console.log(stateValue);
    setChildState(stateValue);
  };

  const handleSelectState = (SelectedValue) => {
    console.log(SelectedValue);
    setSelect(SelectedValue);
  };

  const handleData = async () => {
    try {
      const {data} = await fetchData();
        // console.log(data?.result?.[0]?.data);
      setArr(data?.result?.[0]?.data || []);
    } catch (error) {
      
    }
  };
  
  useEffect(() => {
    handleData()
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = arr.slice(indexOfFirstItem, indexOfLastItem);
   console.log(currentItems);
   
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Container>
        <ProductInfoModal
          isOpen={isModalOpen}
          onPress={() => setIsModalOpen((prev) => !prev)}
          data={cardData}
        />
        <HeroHead />
        <HeroSecondary />

        <Search
          onChildComp={handleChildState}
          onSelectValue={handleSelectState}
        />
      </Container>

      <Container className="my-50">
        <Grid container className="Hero-Product">
          <Grid item xs={12}>
            <p className="category-heading text-center mt-25 mb-2">
              Top Products
            </p>
          </Grid>
          {/* {arr.map((val, index) => {
              return (
                  <ProductCard
                    val={val}
                    key={index}
                    keyNumber={index}
                    onPress={() => setIsModalOpen((prev) => !prev)}
                    onClick={() => setCardData(val)}
                  />
             
              );
            })}
          <div className={`container-prod`}>
            <div className="more-card"><FontAwesomeIcon icon={faAngleRight} className="more-info-btn" />View More</div>
            <br></br>
            
          </div> */}

{currentItems.map((val, index) => {
            return (
              <ProductCard
                val={val}
                key={index}
                keyNumber={index}
                onPress={() => setIsModalOpen((prev) => !prev)}
                onClick={() => setCardData(val)}
              />
            );
          })}
        </Grid>
       <br/>
       <br />
        <Grid container justifyContent="center">
          <Pagination
            count={Math.ceil(arr.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
          
        </Grid>

        {/* Brand */}
      </Container>

      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <p className="category-heading text-center">Choose a category</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              // to="/category"
              // state={{ category: Category.E_SCOOTY }}
              className="linkTag"
            >
              <div className="category-card">
                <img src={EBike1} />
              </div>
              <p className="category-car-title">E-Bike</p>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              // to="/category"
              // state={{ category: Category.E_RICK }}
              className="linkTag"
            >
              <div className="category-card">
                <img src={ERiksha} />
              </div>
              <p className="category-car-title">E-Risksha</p>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              // to="/category"
              // state={{ category: Category.E_LOADER }}
              className="linkTag"
            >
              <div className="category-card">
                <img src={ELoader} />
              </div>
              <p className="category-car-title">E-Loader</p>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid item xs={12}>
          <p className="brand-title  mt-35 mb-5 text-center">
            Find By Brand
          </p>
        </Grid>
        <BrandList />
      </Container>

      {/* footer */}
      <Footer />
    </>
  );
}

