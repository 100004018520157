import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import searchIcon from "../../assets/search.svg";
import { useState } from "react";

export default function Search({ onChildComp, onSelectValue }) {
  const [brand, setBrand] = React.useState("");

  const [childStateValue, setChildStateValue] = useState("");

  const searchChangeHandler = (e) => {
    const valueChange = e.target.value;
    //  console.log(valueChange);
    setChildStateValue(valueChange);
    onChildComp(valueChange);
  };

  const handleChange = (event) => {
    const selectValue = event.target.value;

    setBrand(selectValue);
    onSelectValue(selectValue);
  };

  return (
    <>
      <div
        // style={{
        //   border: "2px solid red",
        //   height: "100px",
        //   margin:"2em 0",
        //   display: "grid",
        //   gridTemplateColumns: "4fr 1fr 1fr",
        //   gap: "1em",
        // }}
        className="Hero-Search"
      >
        <div style={{ }}>
          <Box
            sx={{
              width: 1,
            }}
          >
            <TextField
              InputProps={
                {
                  // startAdornment: <img src={searchIcon} />,
                }
              }
              onChange={searchChangeHandler}
              fullWidth
              label="Search E-vechile"
              id="Width"
              type="text"
              value={childStateValue}
            />
            {}
          </Box>
        </div>
        <div style={{ }}>
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={handleChange}
              // inputProps={{ IconComponent: () => null }}
            >
              <MenuItem value={10}>Piaggio</MenuItem>
              <MenuItem value={20}>Diamond</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div style={{ }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand}
              label="Brand"
              onChange={handleChange}
            >
              <MenuItem value={10}>E-Bike</MenuItem>
              <MenuItem value={20}>E-Rick</MenuItem>
              <MenuItem value={30}>E-Loader</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
}
