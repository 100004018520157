/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBars,
  faPhone,
  faCartShopping,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


import { Container } from "@mui/material";
import logoIco from "../../assets/logo.svg";

import { useState } from "react";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import { Link, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import DealerFormModel from "../Models/DealerFormModel";
import CustomerFormModel from "../Models/CustomerFormModel";

import PiaggaoBrand from "../../assets/navbarIcon/Piaggio-logo.svg";
import DiamondBrand from '../../assets/navbarIcon/logo-jpg.svg'
import { Brand} from "../../Constants/General";

// import { useNavigate } from 'react-router-dom';

export const Navbar = ({ }: any) => {
  const [detailDealer, setDetailDealer] = useState("");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [Piaggio, setPiaggio] = useState("");

  const navigate = useNavigate();

  const handleToggle = () => {
    console.log("Clicked");
    setOpen(!open);
  };

  const opened = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="navbar">
      <Container>
        <div className="nav-container">
          <div className="hello">
            <Link to="/" className="linkTag">
              <img className="logo " src={logoIco} />
              <p className="bg-slate-500 ev ">EV KA BAZAAR</p>
            </Link>
          </div>

          <div className="navbar-toggle burger" >
            <FaBars onClick={handleToggle} style={{height:"38px" , width:"38px"}} />
          </div>

          <div id="menu" className={open ? "menu" : "close-menu"}>
            <div className="Menu-item">
              <a style={{ cursor: "pointer" }}>
                {/* <img src={home} alt="" className="homeImage" /> */}
                <FontAwesomeIcon icon={faHome} className="Icon" />
                <Link to="/">Home</Link>
              </a>
              {/* Category */}
              {/* <a>
                <img src={menu} alt="" className=" align-middle" />

                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <FontAwesomeIcon icon={faBars} />
                      <a
                        {...bindTrigger(popupState)}
                        className="Icon"
                        style={{ cursor: "pointer" }}
                      >
                        Category
                      </a>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => {
                          navigate('/category', { state: { type: Category.E_RICK } })
                          popupState.close();
                        }}
                        >
                          E-Rick
                        </MenuItem>

                        <MenuItem onClick={() => {
                          navigate('/category', { state: { type: Category.E_SCOOTY } })
                          popupState.close();
                        }}
                        >
                          E-Scooty
                        </MenuItem>

                        <MenuItem onClick={() => {
                          navigate('/category', { state: { type: Category.E_LOADER } })
                          popupState.close();
                        }}
                        >
                          E-Loader
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </a> */}

              {/* Brand */}
              <a>
                <FontAwesomeIcon icon={faCartShopping} />
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <a
                        {...bindTrigger(popupState)}
                        className="Icon"
                        style={{ cursor: "pointer" }}
                      >
                        Brand
                      </a>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            navigate('/brand', { state: { type: Brand.PIAGGIO } })
                            popupState.close();
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            Piaggio
                            <img src={PiaggaoBrand} alt="" />
                          </div>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            navigate('/brand', { state: { type: Brand.DIAMOND } })
                            popupState.close();
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "5px", alignItems: "center" }}>
                            Diamond
                            <img src={DiamondBrand} alt="" />
                          </div>
                        </MenuItem>

                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </a>
              <a style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faCartShopping} className="Icon" />
                <Link to="/cart">Cart</Link>
              </a>

              {/* Contact us */}

              <a>
                <FontAwesomeIcon icon={faPhone} />
                <a
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                >
                  Contact us
                </a>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={opened}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  style={{ marginTop: "38px" }}
                >
                  <MenuItem>
                    <DealerFormModel />
                  </MenuItem>
                  <MenuItem>
                    <CustomerFormModel />
                  </MenuItem>
                </Menu>
              </a>
            </div>

            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleToggle}
              className="navbar-toggle"
            />

            {/* Contact as dealer and customer */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
