import Lottie from 'react-lottie';
import noData from '../../assets/lottie/no_data.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export default function NoData() {
    return (
        <div style={{ width:"100%"  }}>
        <Lottie
            options={defaultOptions}
            // height={'12em'}
            // width={'40em'}
            isClickToPauseDisabled={true}
        />
        </div>
    );
}