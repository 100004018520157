import { useEffect, useState } from "react";
// import { fetchCategory, fetchFilterValues } from "../Api";
import { Container, Grid } from "@mui/material";
import {
  BrandFilterData,
  CategoryFilterData,
  FilterValue,
} from "../Constants/General";
import { useLocation, useParams } from "react-router-dom";
import { capitalize } from "../utils";
import ProductCard from "../Components/UI/ProductCard";
import Loader from "../Components/UI/Loader";
import FilterCard from "../Components/UI/FilterCard";
import ProductInfoModal from "../Components/UI/ProductInfoModal";
import NoData from "../Components/UI/NoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import erickIcon from '../assets/loader-final 1.svg';


export default function BrandCategoryPage() {
  let location = useLocation();
  let active_item = location?.state?.type;
  const { page } = useParams();

  const [productsData, setProductsData] = useState([]);
  const [filterValueData, setFilterValuesData] = useState({ category_list: [], brand_list: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({});
  const [itemsPerPage, setItemsPerPage] = useState(8);

  // const loadMore = () => {
  //   setPageNumber((prev) => prev + 1);
  //   setItemsPerPage((prev) => prev + 8);
  //   getProductData(activeFilter, FilterValue[page], pageNumber, itemsPerPage);
  // };

  // const getProductData = async (categoryOrBrand, filtValue, pageNo, limit) => {
  //   setIsLoading(true);
  //   let payload = {
  //     filter: filtValue,
  //     value: categoryOrBrand,
  //     skip: pageNo,
  //     limit: limit,
  //   };
  //   try {
  //     const { data } = await fetchCategory(filtValue, categoryOrBrand, pageNo, limit);
  //     if (data?.status) {
  //       setProductsData(data?.data);
  //       setIsLoading(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };

  // const getFilterValues = async () => {
  //   setIsLoading(true);
  //   try {
  //     const { data } = await fetchFilterValues();
  //     if (data?.status) {
  //       let brand_list_obj = {};
  //       let category_list_obj = {};

  //       data?.data?.brand_list?.map((edx) => {
  //         brand_list_obj[edx] = { 'label': edx, 'icon': erickIcon, 'value': edx }
  //       });

  //       data?.data?.category_list?.map((edx) => {
  //         category_list_obj[edx] = { 'label': edx, 'icon': erickIcon, 'value': edx }
  //       });

  //       setFilterValuesData({ category_list: category_list_obj, brand_list: brand_list_obj })
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // }

  const handleDataSelection = (filt) => {
    setActiveFilter(filt);
    // getProductData(FilterValue[page], filt, 1, 8);
  };

  useEffect(() => {
    setActiveFilter(active_item);
    // getProductData(FilterValue[page], active_item, pageNumber, itemsPerPage);
  }, [active_item]);

  // useEffect(() => {
  //   getFilterValues();
  // }, [])

  return (
    <>
      {isLoading && <Loader />}

      <Container className="my-50">
        <ProductInfoModal
          isOpen={isModalOpen}
          onPress={() => setIsModalOpen((prev) => !prev)}
          data={cardData}
        />

        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ mb: 0 }}>
            <p className="category-heading text-start mt-50 mb-0">
              {capitalize(page)}
            </p>
          </Grid>

          <Grid item xs={12} sx={{ p: 0, m: 0, mb: 5 }}>
            <div className="filter-container">
              {page === "category" ? (
                <FilterCard
                  // filterData={CategoryFilterData}
                  filterData={filterValueData?.category_list}
                  activeFilter={activeFilter}
                  setActiveFilter={handleDataSelection}
                />
              ) : (
                <FilterCard
                  // filterData={BrandFilterData}
                  filterData={filterValueData?.brand_list}
                  activeFilter={activeFilter}
                  setActiveFilter={handleDataSelection}
                />
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {productsData?.length === 0 ? (
            <div className="no-data-container">
              <NoData />
            </div>
          ) : (
            productsData?.map((val, index) => {
              return (
                <Grid item xs={2} sm={4} md={4}>
                  <ProductCard
                    val={val}
                    keyNumber={index}
                    onPress={() => setIsModalOpen((prev) => !prev)}
                    onClick={() => setCardData(val)}
                  />
                </Grid>
              );
            })
          )}

          {/* {productsData?.length === 0 ? null : (
            <div className={`container-prod`}>
              <div className="more-card" onClick={loadMore}><FontAwesomeIcon icon={faAngleRight} className="more-info-btn" />View More</div>
            </div>
          )} */}
        </Grid>
      </Container>
    </>
  );
}
