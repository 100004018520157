import Lottie from 'react-lottie';
import loader from '../../assets/lottie/loader.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export default function Loader() {
    return (
        <div className="blur-bg">
            <div className="loader-cont">
                <Lottie
                    options={defaultOptions}
                    height={'10em'}
                    width={'10em'}
                    isClickToPauseDisabled={true}
                />
            </div>
        </div>
    );
}