import { Routes, Route } from "react-router-dom";
import { Home, BrandCategoryPage } from "../Pages";
import Cart from "../Pages/Cart";

export default function AppRoutes() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:page" element={<BrandCategoryPage />} />
        <Route path="/cart" element={<Cart/>}/>
      </Routes>
  );
}