export const fcINR = (val: number | string, symbol: boolean = true): string => {
  val = Number(val);
  let fc = val?.toLocaleString("en-IN");
  if (!isNaN(val)) {
    if (symbol) return `₹ ${fc}`;
    return fc;
  }
  return "";
};

export const capitalize = (str: string): string => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}
