import piaggio from '../../assets/Piaggio-logo.svg'
import diamondLogo from '../../assets/Diamond.svg'
import CityLogo from '../../assets/cityLife_logo.svg'
import { Brand } from '../../Constants/General'
import { Link } from 'react-router-dom'

// const BrandArr = {
//   b1: piaggio,
//   b2: diamondLogo,
//   b3: CityLogo,
//   b4: piaggio,
//   b5: diamondLogo,
//   b6: CityLogo
// }

const brandList = {
  piaggio: { icon: piaggio, navigateTo: '/brand', brand: Brand.PIAGGIO },
  diamond: { icon: diamondLogo, navigateTo: '/brand', brand: Brand.DIAMOND },
  // dummy1: { icon: piaggio, navigateTo: '/brand', brand: Brand.PIAGGIO },
  // dummy2: { icon: diamondLogo, navigateTo: '/brand', brand: Brand.DIAMOND },
  // dummy3: { icon: piaggio, navigateTo: '/brand', brand: Brand.PIAGGIO },
  // dummy4: { icon: diamondLogo, navigateTo: '/brand', brand: Brand.DIAMOND },
}


export default function BrandList() {
  return (
    // <div className='brand-container'>
    //   {
    //     Object.keys(BrandArr).map((val, ind) => {
    //       return <img src={BrandArr[val]} key={ind} />
    //     })
    //   }
    // </div>
    <div className='brand-container'>
      {
        Object.keys(brandList).map((val, ind) => {
          return (
            <Link to={brandList[val]?.navigateTo} state={{ brand: brandList[val]?.brand }} className="linkTag">
              <img src={brandList[val]?.icon} key={ind} />
            </Link>
          )
        })
      }
    </div>
  )
}